import React from "react";
import "../styles/styles.css";
function Landingpage() {
  return (
    <div className="hero">
      <div className="background-image"></div>
      <h1>JG-website</h1>
      <h3>
        Hello and welcome. My name is Jonathan and this is my personal portfolio
        website.
      </h3>
      <h3>Below are links to my Github, LinkedIn, and EZvisa.io</h3>
      <a
        href="https://github.com/JonathanLGeorge"
        className="btn"
        target="_blank"
        rel="noopener noreferrer"
      >
        GitHub
      </a>
      <a
        href="https://www.linkedin.com/in/jonathan-george-12532316a/"
        className="btn"
        target="_blank"
        rel="noopener noreferrer"
      >
        LinkedIn
      </a>
    </div>
  );
}

export default Landingpage;

/*
<a  href="https://ezvisa.io" className="btn" target="_blank" rel="noopener noreferrer">EZVISA.io</a>
*/
