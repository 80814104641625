import React from "react";
import { Switch, Route } from "react-router-dom";
import Landingpage from "./landingpage";
import Contact from "./contact";
import projects from "./projects";
import Resume from "./resume";
//import Notes from "./Notes";
//import Item from './itemShopDetail'
//import Shop from './shop'

const Main = () => (
  <Switch>
    <Route exact path="/" component={Landingpage} />
    <Route path="/landingpage" component={Landingpage} />
    <Route path="/contact" component={Contact} />
    <Route path="/projects" component={projects} />
    <Route path="/resume" component={Resume} />
    {/* <Route path="/Notes" component={Notes} /> */}
  </Switch>
);

export default Main;
//<Route path="/shop" exact component={Shop}/>
//<Route path="/shop/:id" component={Item}/>
