import React from 'react'
function Resume() {
  return (
    <div className="underConstruction">
      <div className="clear_coat">
      <h1>Jonathan George</h1>
          <h2>Software / Wed Developer</h2>
          <h2>Websites</h2>
          <p>• http://jonathan-george.dev (personal)</p>
          <p>• http://ezvisa.io (co-founded travel website)</p>
          <p>• https://www.linkedin.com/in/jonathan-george-12532316a/</p>
          <p>• https://github.com/JonathanLGeorge </p> 

          <h2>Technical Skills</h2>
          <p class="res_skills">I can design and program Web application, Software, and Apps in multiple languages. I’m knowledgeable of OOP concepts, Data Structures, Design Patterns, Data Bases like SQL, Algorithm Analysis, Back End and Front-End Development, Unit testing, API’s, working with JSON/XML/CSVs, ect…
            I can draft using AutoCAD, and I have some 3D modeling experience using Solid works and 3DSMAX. 
          </p> 
          <p class="res_skills">Proficient working with:
             o	Java, C++, JavaScript(ES5,6+), Babel, HTML, CSS, SCSS, JSON, jQuery, JSX, React, SQL, GIT, WebPack, Microsoft Office, and AutoCAD + 3DSmax.
          </p>
          <p class="res_skills">exposer to:
           o	C, PHP, PostgreSQL, Node.js, Next.js, Bootstrap, Python, Flask, Docker, Linux, and Graphql.
          </p>
        
      </div>
        
    </div>
  );
}

export default Resume;