import React from "react";
import "../styles/styles.css";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <header>
      <nav>
        <Link to="/landingpage" style={{ fontWeight: "bold", color: "white" }}>
          Home
        </Link>

        <ul className="nav-links">
          <Link to="/projects" style={{ fontWeight: "bold", color: "white" }}>
            Projects
          </Link>

          {/* <Link to="/Notes" style={{ fontWeight: "bold", color: "white" }}>
            Notes
          </Link> */}

          <Link to="/resume" style={{ fontWeight: "bold", color: "white" }}>
            Resume
          </Link>

          <Link to="/contact" style={{ fontWeight: "bold", color: "white" }}>
            Contact
          </Link>
        </ul>
      </nav>
    </header>
  );
}

export default Nav;
//<Link to="/shop"style={{fontWeight: 'bold', color: 'white'}}>Shop</Link>
