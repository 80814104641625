/*
import React from 'react';
import axios from 'axios'
import '../styles/styles.css';
import Modal from 'react-modal';

Modal.setAppElement('div');

const customStyles = {
  content: {
      top: 'calc(50% + 27.5px)',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      width: '35%',
      minWidth: '250px',
      height: '25%',
      minHeight: '250px',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: "0",
      borderRadius: "3px",
      border: "none"
  }
};

export default class contact extends React.Component {
  state = {
    name: '',
    nameError: '',
    email: '',
    emailError: '',
    subject: '',
    subjectError: '',
    message: '',
    messageError: '',

    resData: '',
    modalIdOpen: false,

  }

  change = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  
  closeModal = () => {
    this.setState({
      modalIdOpen:false
    });
  }

  onSubmit = (e) => {
    e.preventDeafault();

    if(this.state.name === ''){
      this.setState({
        nameError: 'Please provide your name.'
      })
    } else {
      this.setState({
        nameError: ''
      })
    }

    if(this.state.email === ''){
      this.setState({
        emailError: 'Please provide a valid email.'
      })
    } else if (this.state.email === /^((?!@).)*$/ || this.state.email.indexOf('.') === -1){
      this.setState({
        emailError: 'Please provide a valid email.'
      })
    } else {
      this.setState({
        emailError: ''
      });
    }

    if(this.state.subject === ''){
      this.setState({
        subjectError: 'please provide a message subject.'
      });
    } else {
      this.setState({
        subjectError: ''
      })
    }

    if(this.state.message === ''){
      this.setState({
        messageError: 'Please write me message.'
      })
    } else {
      this.setState({
        messageError: ''
      })
    }

    setTimeout(() => {
      if(this.state.nameError === '' && this.state.emailError === '' && this.state.subjectError === '' && this.state.messageError === ''){
        axios.post('/send-email', {
          name: this.state.name,
          email: this.state.email,
          subject: this.state.subject,
          message: this.state.message
        }, {
          headers: {
            'contact-type': 'aplication.json',
          },
        }).then(response => {
          this.setState({
            resData: response.data
          });
          this.setState({
            modalIdOpen: true
          });
          this.setState({
            name: '',
            email: '',
            subject: '',
            message: ''
          })
        }).catch(function (error) {
          console.log(error.response)
        })
      } else {
        this.setState({
          resData: 'Something went wrong. Please direct you message to jonathan.george.dev@gmail.com and ill get back to you.'
        });
      }
    }, 10);
  }


  render() {
    return (
        <div id="contactForm">
            <div
                header="Let's Get In Touch"
                subHeader="Tell me about yourself and what I can do for you."
                bg="contact-bg"
            />
            <div className="full-width-row cta-form--bg">
                <div className="row-container">
                    <form className="cta-form">
                        <label className="cta-form__label" htmlFor="name">Name</label>
                        <input
                            name="name"
                            value={this.state.name}
                            onChange={e => this.change(e)}
                            type="text"
                            className="cta-form__input"
                            id="name"
                            style={{ margin: `${this.state.nameError.length > 0 ? "0" : ''}` }}
                        />
                        <p className="form-error">{this.state.nameError}</p>
                        <label className="cta-form__label" htmlFor="email">Email</label>
                        <input
                            name="email"
                            value={this.state.email}
                            onChange={e => this.change(e)}
                            type="text"
                            className="cta-form__input"
                            id="email"
                            style={{ margin: `${this.state.emailError.length > 0 ? "0" : ''}` }}
                        />
                        <p className="form-error">{this.state.emailError}</p>
                        <label className="cta-form__label" htmlFor="subject">Subject</label>
                        <input
                            name="subject"
                            value={this.state.subject}
                            onChange={e => this.change(e)}
                            type="text"
                            className="cta-form__input"
                            id="subject"
                            style={{ margin: `${this.state.subjectError.length > 0 ? "0" : ''}` }}
                        />
                        <p className="form-error">{this.state.subjectError}</p>
                        <label className="cta-form__label" htmlFor="message">Message</label>
                        <textarea
                            name="message"
                            onChange={e => this.change(e)}
                            value={this.state.message}
                            className="cta-form__textarea"
                            id="message"
                            style={{ margin: `${this.state.messageError.length > 0 ? "0" : ''}` }}
                        />
                        <p className="form-error">{this.state.messageError}</p>
                        <button
                            className="btn btn--white"
                            onClick={e => this.onSubmit(e)}
                        >
                            Send
                        </button>
                    </form>
                </div>
            </div>

            <Modal
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                contentLabel="Example Modal"
                style={customStyles}
                closeTimeoutMS={150}
                overlayClassName="formOverlay"
            >
                <div className="modalHeader-container">
                    <span className="modalHeader-container__text">Your message was sent!</span>
                    <i className="fa fa-times modalHeader-container__close-icon" aria-hidden="true" onClick={this.closeModal}></i>

                </div>

                <div className="modal-body">
                    <p className="modal-body__text">{this.state.resData}</p>
                    <p>I'll be in touch with you soon.</p>
                </div>
                <button className="btn btn--white" style={{
                    textAlign: "center", margin: "10px auto",
                    display: "block"
                }} onClick={this.closeModal}>Close</button>
            </Modal>
        </div>
    );
}
}

*/

import React from "react";
import "../styles/styles.css";

function Contact() {
  return (
    <div className="underConstruction">
      <h1 class="contactInfo">
        If you would like to reach out to me, please fill out the form below and
        Ill get back to you as soon as I can. Thankyou and have a blessed day.
        :)
      </h1>
      <form name="contact v4" method="post" data-netlify-honeypot="bot-field">
        <input type="hidden" name="form-name" value="contact v1" />
        <p hidden>
          <label>
            Dont fill this out<input type="text" name="bot-field"></input>
          </label>
        </p>
        <div class="fields">
          <div class="field_half">
            <h3>Please provide out your name</h3>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="NAME"
              required
            />
          </div>
          <div class="fields_half">
            <h3>please provide your contact email.</h3>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="E-MAIL"
              required
            />
          </div>
          <div class="field">
            <h3>Please leave me a message :)</h3>
            <textarea
              name="message"
              id="message"
              placeholder="Write your message here"
              rows="5"
            ></textarea>
          </div>

          <div class="field">
            <div data-netlify-recaptcha="true"></div>
          </div>

          <input
            type="submit"
            value="Send Message"
            class="button primary"
            required
          ></input>
        </div>
      </form>
    </div>
  );
}

export default Contact;

/*

<div class="field">
  <input type="file" name="myFile" id="myFile" placeholder="Upload File" rows="5"/>
</div>


*/
