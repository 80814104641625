import React from "react";
//we should make an update for card-title. so that its rendering the correct images
function ProjectCard({ props }) {
  return (
    <section className="card">
      <h1 className="card-title">{props.name}</h1>
      <p className="card-text">{props.description}</p>
      <div className="card-buttons">
        <form
          className="card-actions"
          action={props.actionWeb}
          method="get"
          target="_blank"
        >
          <button className="card-button" type="submit">
            WebSite
          </button>
        </form>
        <form
          className="card-actions"
          action={props.actionGit}
          method="get"
          target="_blank"
        >
          <button className="card-button" type="submit">
            Github
          </button>
        </form>
      </div>
    </section>
  );
}

export default ProjectCard;
