import React, { Component } from "react";
import TicTacToe from "../tictactoe/TicTacToe";
import ProjectCard from "./ProjectCard";

const CardInfo = {
  firebase: {
    name: "Chat App",
    description:
      "Online Chat App, using Redux, FireBase, and Google user login.",
    actionWeb: "https://chat-clone-fd92b.web.app/",
    actionGit: "https://github.com/JonathanLGeorge/chat_clone",
  },
  price_toggle: {
    name: "Price toggle",
    description:
      "Price toggle is a Front-end Mentor mini project. It showcases custom CSS and responsive design.",
    actionWeb: "https://jonathanlgeorge.github.io/price_toggle/",
    actionGit: "https://github.com/JonathanLGeorge/price_toggle",
  },
  flags: {
    name: "FLAGS",
    description:
      "Flags is a Front-end Mentor mini project. It uses react routing and some async code to fect data from an API.",
    actionWeb: "https://jonathanlgeorge.github.io/frontend_flags/#/home",
    actionGit: "https://github.com/JonathanLGeorge/frontend_flags",
  },
  four_cards: {
    name: "Four Cards",
    description:
      "Four cards in another Front-end mentor project that showcases responsive design and custom CSS.",
    actionWeb: "https://jonathanlgeorge.github.io/four_cards/",
    actionGit: "https://github.com/JonathanLGeorge/four_cards",
  },
};

class projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activetab: 0,
    };
  }

  toggleCat() {
    if (this.state.activetab === 0) {
      return (
        <div className="projects-grid">
          {/* <div className="card">
            <h1 className="card-title">EZVISA</h1>
            <p className="card-text">
              EZVisa.io is a travel assist website that aids you with visa
              information nessesary for world wide travel.
            </p>
            <div className="card-buttons">
              <form
                className="card-actions"
                action="https://ezvisa.io/"
                method="get"
                target="_blank"
              >
                <button className="card-button" type="submit">
                  WebSite
                </button>
              </form>
            </div>
          </div> */}
          <ProjectCard props={CardInfo.firebase} />
          <ProjectCard props={CardInfo.price_toggle} />
          <ProjectCard props={CardInfo.flags} />
          <ProjectCard props={CardInfo.four_cards} />
        </div>
      );
    } else if (this.state.activetab === 1) {
      return (
        <div className="projects-grid">
          <div className="card">
            <h1 className="card-title-java">Block Game</h1>
            <p className="card-text">
              Java built game about avoiding randomly generated objects. Survive
              a long time to get a high score
            </p>
            <div className="card-buttons">
              <form
                className="card-actions"
                action="https://github.com/JonathanLGeorge/Game"
                method="get"
                target="_blank"
              >
                <button className="card-button" type="submit">
                  Github
                </button>
              </form>
            </div>
          </div>

          <div className="card">
            <h1 className="card-title-java">Dragon ball tic tac toe</h1>
            <p className="card-text">
              A game of Tic-Tac-Toe, built in andriod studio with touch
              controls.
            </p>
            <div className="card-buttons">
              <form
                className="card-actions"
                action="https://github.com/JonathanLGeorge/dragon_ball"
                method="get"
                target="_blank"
              >
                <button className="card-button" type="submit">
                  Github
                </button>
              </form>
            </div>
          </div>
        </div>
      );
    } else if (this.state.activetab === 2) {
      return (
        <div className="projects-grid">
          <ProjectCard props={CardInfo.firebase} />
        </div>
      );
    } else if (this.state.activetab === 3) {
      return (
        <div className="projects-grid">
          <TicTacToe />
        </div>
      );
    }
  }

  render() {
    return (
      <div className="category-tabs">
        <div
          className="active-tab"
          activetab={this.state.activetab}
          onChange={(tabId) => this.setState({ activetab: tabId })}
          ripple="true"
        >
          <h3
            className="active-tab-links"
            onClick={(tabId) => this.setState({ activetab: 0 })}
          >
            React
          </h3>
          <h3
            className="active-tab-links"
            onClick={(tabId) => this.setState({ activetab: 1 })}
          >
            Java
          </h3>
          <h3
            className="active-tab-links"
            onClick={(tabId) => this.setState({ activetab: 2 })}
          >
            FireBase
          </h3>
          {/* <h3
            className="active-tab-links"
            onClick={(tabId) => this.setState({ activetab: 3 })}
          >
            TicTacToe
          </h3> */}
        </div>

        <div>
          <div className="content">{this.toggleCat()}</div>
        </div>
      </div>
    );
  }
}

export default projects;

//<button class='tab' onClick="openFolder(even, 'React')">React</button>
//<table>html</table>
